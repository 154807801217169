$size: 80px;

.layers-sidebar {
  z-index: 250;
  .dropdown-menu.show {
    width: 360px;
    overflow: scroll;
    border-radius: 0;
    box-shadow: none;
  }

  .row {
    margin: unset;
    padding: unset;
    width: unset;
    margin-left: 50px;

    .col {
      margin: unset;
      padding: unset;
    }
  }

  .thumbnail {
    text-align: center;
    width: $size;

    .disabled {
      background-color: rgba(0, 0, 0, 0.337);
    }

    img.layer {
      width: $size;
      cursor: pointer;
      width: 80px;
      height: 80px;
      background-color: rgba(255, 255, 0, 0.446);
      border-radius: 8px;
    }

    img.layer:hover {
      border: 3px solid $te-light;
      box-sizing: border-box;
      border-radius: 10px;
    }

    img.check {
      position: relative;
      left: 25px;
      bottom: 80px;
      visibility: hidden;
    }

    .description {
      font-size: 12px;
      position: relative;
      left: 0px;
      top: -20px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .thumbnail.selected {
    img.layer {
      border: 3px solid $te-light;
      box-sizing: border-box;
      border-radius: 10px;
    }
    img.check {
      visibility: inherit;
    }
  }
}

button {
  border: 0 !important;
}

.accordion-button {
  color: white;
  background-color: #18244c;
  border: 0px !important;
}

.accordion-button::after {
  border: 0px !important;
  filter: invert(100%);
}

.accordion-button:not(.collapsed)::after {
  filter: invert(50%);
  border: 0px !important;
  color: white !important;
}

.dropdown-menu {
  
}

.selected {
  border: 3px solid gold;
}