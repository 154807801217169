
.sidebar {
  min-width: 80px;
  max-width: 80px;
  height: 100vh;
  background: $te-light;
  color: white;
  margin-left: -250px;
  transition: all 0.5s;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  .views {
    position: absolute;
    bottom:2%;
    padding: 12px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    .view-switch {
      transform: rotate(270deg);
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .flex-column {
    top: 35%;
    position: absolute;
  }

  a {
    color: white;
    background: $te-light;
  }
  svg {
    display: block;
    width: 100%;
  }

  .title {
    font-size: 20px;
    svg {
      display: inline;
      color: white;
      margin-right: 10px;
      width: auto;
      margin-left: 12px;
    }
  }

  .dashboard {
    position: absolute;
    top: 90px;
    height: 20px;
    font-size: .9rem;
    cursor: pointer;

    svg {
      transform: translateY(-8px) scale(1.5);
    }
  }
  
  .dashboard-active {
    color: white;
  }

  .dashboard:hover {
    color: white;
  }

  //----------------DROPDOWN SUBMENU---------------------

  
  .nav-item.active {
    background: unset;
  }

  .dropdown {
    background-color: unset;
    outline: unset;

    button.dropdown-toggle {
      border: unset;
    }

    button.dropdown-toggle:hover {
      color: white;
    }

    .dropdown-toggle.btn.btn-success {
      border: unset;
    }
  }

  .dropdown.show {
    background-color: unset;

    button.dropdown-toggle {
      border: unset;
      background-color: unset;
      color: white;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-toggle {
    background-color: unset;
    border-color: unset;
  }

  .dropdown-menu.show {
    background-color: $te-light;
    transform: translate(77px, -200px) !important;
    opacity: 0.98;
    color: white;
    max-height: 80vh;
    overflow: auto;

    .dropdown-item {
      background-color: $te-light;
    }

    .layers {
      overflow: scroll;
    }
  }

  .layers-sidebar.thumbnail img.layer {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  //----------------------ACCORDION------------------------
  .accordion {
    .accordion-item {
      border: unset;
      background-color: unset;
    }
    .accordion-body {
      margin-bottom: 10px;
    }
    .accordion-button {
      background: unset;
      color: white;

      &:hover {
        color: white;
      }
    }   
    .accordion-collapse {
      color: white !important;
    }
    .accordion-header button::after {
      border: 0px !important;
      filter: invert(50%) !important;
    }
    .report-link {
      display: block;
      text-decoration: none;
      background: unset;
      text-indent: -10px;
      margin-left: 20px;
    }
    .report-link:hover {
      color: white;
    }
    div.accordion-collapse.collapse.show {
      background: unset;
    }
  }
}

//--
.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: $te-light;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: white;
  padding: 1em;
}

.sidebar ul p {
  color: white;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: $te-light;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

#report-btns {
  margin-top: 3rem;
}
