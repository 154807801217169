@import "./variables";
@import "../../components/main/navbar";
@import "../../components/main/right-header";
@import "../../components/main/right-drawer";
@import "../../components/main/breadcrumb";
@import "./globals";
@import "../../components/DrinkingWaterQuality/water-quality-list";
@import "../../components/main/loading-spinner";
@import "../../pages/home";
@import "../../components/DrinkingWaterQuality/render-popup";
@import "../../reports/DrinkingWaterTrueQI/dw-styles";
@import "../../components/main/sidebar";
@import "../../components/main/layers";



.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.reportTable {
  font-size: 18px !important;
}

.reportTable .report-table__header {
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 1;
}

.sidebar-menu {
  overflow: auto;
}

.app-other {
  .report-screen {
    display: none;
  }

  .views {
    display: none;
  }

  #report-btns {
    display: none;
  }

  .fullscreen {
    display: none;
  }
}

.data-container {
  padding-top: 63px !important;
}

.report-content {
  margin-top: 50px;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;

  .report-content__title {
    color: #fff;
  }
}

.table-row__selected {
  background-color: #021d3e;
}

.report__container-background {
  border-radius: 12px;
  box-shadow: 0px 0px 20px #000;
}

.source-footer-label {
  text-align: right;
  padding-top: 1rem;
}
.breadcrumb-subtitle {
  font-weight: bold;
  top: 0;
  position: relative;
  margin-bottom: 0;
}

.container--expand-container {
  position: absolute;
  top: 52px !important;
  left: 5.2rem;
  height: 93vh !important;
  width: 95%;
  z-index: 100;

  .row.chart-container {
    height: 80vh !important;
  }
}
